import React from 'react';
// icon
import { BsArrowUpRight } from 'react-icons/bs';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';

// services data
const services = [
  {
name: '3D Animation',
description:
'Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque non autem.',
link: 'Learn more'
  },
  {
    name: 'Logo Designing',
    description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque non autem, tempora minima deserunt totam voluptatibus incidunt sequi.',
    link: 'Learn more'
      },
      {
        name: 'Social media Marketing',
        description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque non autem, tempora.',
        link: 'Learn more'
          },
          {
            name: 'Web designing',
            description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque non autem, tempora minima deserunt.',
            link: 'Learn more'
              },
];



const Services = () => {
  return (
  <section className='section' id='services'> 
  <div className='container mx-auto'>
<div className='flex flex-col lg:flex-row'>
{/* text & image*/}
<motion.div 
variants={fadeIn('right', 0.3)}
initial='hidden'
whileInView={'show'}
viewport={{ once: false, amount: 0.3 }}
className='flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend mb-15 lg:mb-14'>
  <h2 className='h2 text-accent mb-6'>What I Do</h2>
  <h3 className='h3 max-w-[455px] mb-15'>
    I'm a Freelance Animator and Graphic designer.</h3>
    <a href='https://www.upwork.com/workwith/talhaofficial'><button className='btn btn-sm'>See my work</button></a>
</motion.div>
{/* services */}
<motion.div 
variants={fadeIn('left', 0.5)}
initial='hidden'
whileInView={'show'}
viewport={{ once: false, amount: 0.3 }}
className='flex-1'>
  {/*service list */}
  <div>
  {services.map((service, index) => {
  // destructure service
  const { name, description, link } = service;
  return ( 
  <div 
  className='border-b border-black/20 h-[164px] mb-[38px] flex'
   key={index}>
    <div className='max-w-[476px]'>
      <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-6'>
        {name}
        </h4>
      <p className='font-secondary leading-tight'>
        {description}
        </p>
    </div>
    <div className='flex flex-col flex-1 items-end'>
      <a href="#" className='btn w-9 h-9 mb-[42px] flex justify-center items-center'>
        <BsArrowUpRight />
        </a>
        <a href="#" className='text-gradient text-sm'>
          {link}
          </a>
    </div>
  </div>
  );
})}
</div>
</motion.div>
</div>
</div>
</section>
  );
};

export default Services;
